import React from "react";

import logo from "assets/img/cb-logo.png";

const Footer = () => (
  <footer id="footer" className="border-top">
    <div className="container">
      <div className="border-bottom">
        <div className="row justify-content-lg-between space-2 space-top-md-3">
          <div className="col-lg-3 order-lg-1">
            <div className="col-lg-3 order-lg-1">
              <div className="d-flex align-items-start flex-column h-100">
                <a href="/" className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center">
                  <img src={logo} className="cb-logo" alt="Craftingbot Logo" />
                  <span className="u-header__navbar-brand-text">
                    氪林博科技
                  </span>
                </a>

                <p className="text-muted mt-5 ml-1" style={{width: '200px'}}>
                  <i className="far fa-company"></i>
                  武汉市氪林博科技有限公司
                </p>
              </div>
            </div>
          </div>
          
          <div className="col-6 col-sm-4 col-lg-2 mb-7 mb-lg-0 order-lg-2">
            <h4 className="h6">关于</h4>

            <ul className="list-group list-group-flush list-group-borderless mb-0">
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="/"
                >
                  公司概况
                </a>
              </li>
              
            </ul>
          </div>

          <div className="col-6 col-sm-4 col-lg-2 mb-7 mb-lg-0 order-lg-3">
            <h4 className="h6">成功案例</h4>

            <ul className="list-group list-group-flush list-group-borderless mb-0">
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#case-study"
                >
                  Frankgreen Pay
                </a>
              </li>
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#case-study"
                >
                  保育の求人なら
                </a>
              </li>
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#case-study"
                >
                  奇驾 App
                </a>
              </li>
            </ul>
          </div>

          <div className="col-sm-4 col-lg-2 mb-7 mb-lg-0 order-lg-4">
            <h4 className="h6">解决方案与产品</h4>
            <ul className="list-group list-group-flush list-group-borderless mb-0">
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#solution"
                >
                  视频会议与教学
                </a>
              </li>
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#solution"
                >
                  自媒体一站式平台
                </a>
              </li>
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#solution"
                >
                  在线学习与考试
                </a>
              </li>
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#solution"
                >
                  ELK大数据解决方案
                </a>
              </li>
            </ul>
          </div>

          <div className="col-sm-4 col-lg-2 mb-7 mb-lg-0 order-lg-5">
            <h4 className="h6">联系我们</h4>
            <ul className="list-group list-group-flush list-group-borderless mb-0">
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#case-study"
                >
                  <span className="far fa-phone mr-1"></span>
                  hello@craftingbot.com
                </a>
              </li>
              <li>
                <a
                  className="list-group-item list-group-item-action"
                  href="#case-study"
                >
                  <span className="far fa-map-marker-alt mr-1"></span>
                  武汉市东湖新技术开发区关山大道1号1.2期光谷企业公馆C2栋103室
                </a>
              </li>
              
            </ul>
          </div>
          

        </div>
      </div>

      <div className="text-center py-7">
        <p className="small text-muted mb-0"><a href="https://beian.miit.gov.cn/" >鄂ICP备19016014号-1</a> © 武汉氪林博科技公司 版权所有</p>
      </div>
    </div>
  </footer>
);

export default Footer;
