import React from 'react';

import Header from 'components/Header';
import Main from 'components/Main';
import Home from 'components/Home';
import Service from 'components/Service';
import Cases from 'components/Cases';
import Footer from 'components/Footer';
import Solution from 'components/Solutions';

function App() {
  return (
    <div className="App">
      <header className="u-header u-header--abs-top-md u-header--bg-transparent u-header--show-hide-md">
        <Header></Header>
        <Main>
          <Home></Home>
          <Service></Service>
          <Cases></Cases>
          <Solution></Solution>
          <Footer></Footer>
        </Main>
      </header>
    </div>
  );
}

export default App;
