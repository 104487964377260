import React from "react";
import logo from "assets/img/cb-logo.png";

const Header = () => (
  <div className="container">
    <nav className="navbar navbar-expand-md u-header__navbar u-header__navbar--no-space hs-menu-initialized hs-menu-horizontal">
      <a href="/" className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center">
        <img src={logo} className="cb-logo" alt="Craftingbot Logo"/>
        <span className="u-header__navbar-brand-text">氪林博科技</span>
      </a>
      <ul className="navbar-nav u-header__navbar-nav">
        <li className="nav-item u-header__nav-item">
          <a href="#home" className="nav-link u-header__nav-link u-header__nav-link">
            首页
          </a>
        </li>
        <li className="nav-item u-header__nav-item">
          <a href="#service" className="nav-link u-header__nav-link u-header__nav-link">
            服务
          </a>
        </li>
        <li className="nav-item u-header__nav-item">
          <a href="#case-study" className="nav-link u-header__nav-link u-header__nav-link">
            成功案例
          </a>
        </li>
        <li className="nav-item u-header__nav-item">
          <a href="#solutions" className="nav-link u-header__nav-link u-header__nav-link">
            产品 & 解决方案
          </a>
        </li>
        <li className="nav-item u-header__nav-item">
          <a href="#footer" className="nav-link u-header__nav-link u-header__nav-link">
            联系我们
          </a>
        </li>
      </ul>
    </nav>
  </div>
);

export default Header;
