import React from "react";

import case1 from "assets/img/fg-pay.png";
import case2 from "assets/img/fine.png";
import case3 from "assets/img/qijia.png";

const Cases = () => (
  <div id="case-study">
    <div className="container space-2 space-md-3">
      <div className="row justify-content-md-between align-items-center mb-7">
        <div className="col-lg-5 mb-7 mb-lg-0">
          <h2 className="font-weight-normal mb-0">
            更多的
            <span className="text-primary font-weight-semi-bold">成功案例</span>
          </h2>
        </div>

        <div className="col-lg-6 text-lg-right mt-lg-auto">
          <a href="#solutions">
            更多成功案例
            <span className="far fa-arrow-right small ml-2" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb-7 mb-lg-0">
          <a
            className="card border-0 shadow-sm transition-3d-hover"
            href="#case-study"
          >
            <div className="card-body p-0">
              <div className="text-center bg-primary">
                <img
                  className="card-img-top"
                  src={case1}
                  style={{ maxWidth: "210px" }}
                  alt="Frankgreen Pay"
                />
              </div>

              <div className="text-center p-5">
                <h4 className="h5 text-dark mb-0">
                  <span className="">Frankgreen Pay</span>
                </h4>
                <p className="mb-0">
                  <span className="far fa-map-marker-alt"> 澳大利亚</span> -
                  移动支付平台
                </p>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-4">
          <a
            className="card border-0 shadow-sm transition-3d-hover"
            href="#case-study"
          >
            <div className="card-body p-0">
              <div className="text-center bg-danger">
                <img
                  className="card-img-top"
                  src={case2}
                  style={{ maxWidth: "210px" }}
                  alt="FINE"
                />
              </div>

              <div className="text-center p-5">
                <h4 className="h5 text-dark mb-0">保育の求人なら</h4>
                <p className="mb-0">
                  <span className="far fa-map-marker-alt"> 日本</span> -
                  幼儿教育招聘平台
                </p>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-4">
          <a
            className="card border-0 shadow-sm transition-3d-hover"
            href="#case-study"
          >
            <div className="card-body p-0">
              <div className="text-center bg-success">
                <img
                  className="card-img-top"
                  src={case3}
                  style={{ maxWidth: "210px" }}
                  alt="Qijia"
                />
              </div>

              <div className="text-center p-5">
                <h4 className="h5 text-dark mb-0">奇驾 App</h4>
                <p className="mb-0">
                  <span className="far fa-map-marker-alt"> 国内</span> -
                  车联网应用
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Cases;
