import React from "react";

import pointer from "theme/svg/components/pointer-1.svg"

const Service = () => (
  <div id="service" className="container space-top-1 space-bottom-md-3 space-bottom-2">
    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
      <span className="btn btn-xs btn-soft-success btn-pill mb-2">我们的服务</span>
      <h2>为您创造新时代的优势</h2>
    </div>
    <div className="row">
      <div className="col-md-4 mb-7">
        <div className="text-center px-lg-3">
          <span className="btn btn-icon btn-lg btn-soft-danger rounded-circle mb-5">
            <span className="fab fa-yelp fa-2x btn-icon__inner btn-icon__inner-bottom-minus" />
          </span>
          <h3 className="h5">互联网化的创新</h3>
          <p className="mb-md-0">
            产品原型设计、精益流程管理、大数据和物联网解决方案、产品开发和运维
          </p>
        </div>
      </div>

      <div className="col-md-4 mb-7">
        <div className="text-center px-lg-3">
          <span className="btn btn-icon btn-lg btn-soft-primary rounded-circle mb-5">
            <span className="far fa-fire fa-2x btn-icon__inner btn-icon__inner-bottom-minus" />
          </span>
          <h3 className="h5">用户体验设计</h3>
          <p className="mb-md-0">
            典型用户行为研究、交互设计、体验原型设计、视觉与品牌设计
          </p>
        </div>
      </div>

      <div className="col-md-4 mb-7">
        <div className="text-center px-lg-3">
          <span className="btn btn-icon btn-lg btn-soft-success rounded-circle mb-5">
            <span className="fab fa-whmcs fa-2x btn-icon__inner btn-icon__inner-bottom-minus" />
          </span>
          <h3 className="h5">定制开发 & 解决方案</h3>
          <p className="mb-md-0">
            敏捷开发流程、Web & Mobile 应用程序开发、Saas
            服务集成与开发、软件交付和遗留系统重构
          </p>
        </div>
      </div>
    </div>

    <img
      className="img-fluid d-none d-md-block w-75 mx-auto mb-7"
      src={pointer}
      alt="SVG Arrow"
    />

    <div className="w-md-60 w-lg-50 text-center mx-auto mb-7">
      <h4 className="h5">
        我们拥有国内顶尖的软件工程师、用户体验设计师和咨询顾问。
        <span className="font-weight-normal">
          我们为不同行业的客户提供互联网时代具有创新价值的产品解决方案，我们的专业领域知识和创新能力帮助客户应对每一个商业增长、交付卓越的产品。
        </span>
      </h4>
    </div>
  </div>
);

export default Service;
