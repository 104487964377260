import React from 'react';

const Main = (props) => (
  <main id="content" role="main">
    <div>
    {props.children}
    </div>
  </main>
)

export default Main;