import React from "react";
import apps from "assets/img/service-mobile-apps.png";

const Home = () => (
  <div id="home" className="container space-bottom-2">
    <div className="row cb-home--container">
      <div className="col-md-6 position-relative z-index-2 space-top-3 space-top-md-5 space-lg-4 ">
        <div className="mb-4">
          <h1 className="display-4 font-weight-normal">
            拥抱创新，创造价值
          </h1>
        </div>
        <div className="mb-7">
          <p className="lead">
            从产品原型开发，到产品商业价值提升，我们都将最前沿的技术与实践融入产品。持续将创新的技术与商业价值紧密结合。
          </p>
        </div>
        <div className="d-flex align-items-center flex-wrap">
        <a className="btn btn-primary btn-wide transition-3d-hover" href="#footer">联系我们</a>
        </div>
      </div>
      <div className="col-md-6 space-top-2 space-lg-3 text-center">
       <img src={apps} alt="App Development"/>
      </div>
    </div>
  </div>
);

export default Home;
