import React from "react";

import case1 from "assets/img/meeting.png";
import case2 from "assets/img/exams.png";
import case3 from "assets/img/we-media.png";
import case4 from "assets/img/elk.jpg";

const Solution = () => (
  <div id="solutions" className="gradient-half-primary-v2">
    <div className="container space-2 space-md-3">
      <div className="row justify-content-md-between align-items-center mb-7">
        <div className="col-lg-5 mb-7 mb-lg-0">
          <h2 className="font-weight-normal mb-0">
            我们自研的
            <span className="text-primary font-weight-semi-bold">解决方案和产品</span>
          </h2>
        </div>

        <div className="col-lg-6 text-lg-right mt-lg-auto">
          <a href="/">
            敬请期待
            <span className="far fa-arrow-right small ml-2" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 mb-7 mb-lg-0">
          <a
            className="card border-0 shadow-sm transition-3d-hover"
            href="#case-study"
          >
            <div className="card-body p-0">
              <div className="text-center bg-info">
                <img
                  className="card-img-top"
                  src={case1}
                  
                  alt="Conference"
                />
              </div>

              <div className="text-center p-5">
                <h4 className="h5 text-dark mb-0">
                  <span className="">视频会议与教学</span>
                </h4>
                <p className="mb-0">
                  <span className="far fa-webcam"> 教育或培训</span> -
                  在线视频会议 / 播放ppt
                </p>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3">
          <a
            className="card border-0 shadow-sm transition-3d-hover"
            href="#case-study"
          >
            <div className="card-body p-0">
              <div className="text-center bg-danger">
                <img
                  className="card-img-top"
                  src={case2}
                  alt="FINE"
                />
              </div>

              <div className="text-center p-5">
                <h4 className="h5 text-dark mb-0">在线学习与考试</h4>
                <p className="mb-0">
                  <span className="far fa-file-signature"> 测试与考评</span> -
                  在线组织题库 / 在线测评考试
                </p>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3">
          <a
            className="card border-0 shadow-sm transition-3d-hover"
            href="#case-study"
          >
            <div className="card-body p-0">
              <div className="text-center bg-success">
                <img
                  className="card-img-top"
                  src={case3}
                  alt="We-media publish"
                />
              </div>

              <div className="text-center p-5">
                <h4 className="h5 text-dark mb-0">自媒体一站式平台</h4>
                <p className="mb-0">
                  <span className="far fa-photo-video"> 自媒体内容</span> -
                  自媒体内容编辑 / 一站式发布多平台
                </p>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3">
          <a
            className="card border-0 shadow-sm transition-3d-hover"
            href="#case-study"
          >
            <div className="card-body p-0">
              <div className="text-center bg-success">
                <img
                  className="card-img-top"
                  src={case4}
                  alt="Qijia"
                />
              </div>

              <div className="text-center p-5">
                <h4 className="h5 text-dark mb-0">ELK大数据解决方案</h4>
                <p className="mb-0">
                  <span className="far fa-database"> 数据服务</span> -
                  海量数据索引 / 存储 / 检索 解决方案
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Solution;
